import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  background: rgba(0, 0, 0, 0);
  width: 70vw;
  margin: 0 auto;
  padding: 30px 30px 10px;
  box-shadow: 0 0 150px -20px rgba(0, 0, 0, 0.5);
  font-family: 'Comfortaa', sans-serif;
  color: #e9e9ed;
  position: relative;
  z-index: 2; 
  margin-top: 10px; 
`;

const Heading = styled.h1`
  font-size: 15vw;
  text-align: center;
  color: #ffffff26;
  text-shadow: 0 0 50px rgba(0, 0, 0, 0.07);
  font-family: 'Comfortaa', sans-serif;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export const P = styled.p`
  margin: 0 0 20px;
  font-size: 17px;
  line-height: 1.2;
`;

export const Span = styled.span`
  color: #f56c13  ;
`;

export const B = styled.b`
  color: #46505e;
`;

const TermsAndConditions = () => {
  const [content, setContent] = useState('');

  useEffect(() => {
    let str = document.getElementById('content').innerHTML.toString();
    let i = 0;
    setContent('');

    const interval = setInterval(() => {
      i++;
      setContent(str.slice(0, i));
      if (i === str.length) {
        clearInterval(interval);
      }
    }, 10);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ backgroundColor: '#0b0f19', minHeight: '100vh', paddingTop: '50px', paddingBottom: '50px', position: 'relative' }}>
      
      {/* <Heading>Terms & Conditions</Heading> */}
      <Container>
        <div id="content" style={{ display: 'none' }}>
          <P> <Span>Section 1: Introduction</Span></P>
          <P> <Span>Purpose of the Terms and Conditions:</Span></P>
          <P>_"This Terms and Conditions agreement ("Agreement") is a legally binding contract between you ("User") and [Your Company Name] ("decentre.io", "we", "us") governing your use of the decentre.io website and its associated software and services ("Services"). "_</P>
          <P> <Span>Acceptance of Terms:</Span></P>
          <P>"By accessing or using the Services, you acknowledge that you have read, understood, and agreed to be bound by all the terms and conditions within this Agreement. If you do not agree to these terms, you must not use the Services."</P>
          <P> <Span>Updates to the Agreement</Span></P>
          <P>"We reserve the right to modify this Agreement at any time. We will notify you of modifications by posting the updated Agreement on our website with a "last updated" date. Your continued use of the Services after the posting of changes constitutes your acceptance of such changes."</P>
  
          <P> <Span>Section 2: Account Creation</Span></P>
          <P><B>Eligibility</B></P>
          <P>Age: Clearly state any minimum age requirements (e.g., "You must be at least 18 years old to use the Services.").</P>
          <P>Location: If you have geographic restrictions, specify them (e.g., "Our Services are not intended for use in countries where prohibited by law.").</P>
          <P>Other Restrictions (Optional): If applicable, outline any other limitations (e.g., "Individuals previously banned from the Services may not create new accounts.").</P>
  
          <P><B>Account Information</B></P>
          <P>Accuracy: "You agree to provide accurate, current, and complete information during the account registration process and maintain its accuracy."</P>
          <P>Necessary Data: Specify the minimum data needed for account creation (usually email address and a chosen password).</P>
  
          <P><B>Account Security</B></P>
          <P>User Responsibility: "You are responsible for maintaining the confidentiality of your login credentials and all activities occurring under your account."</P>
          <P>Unauthorized Access: "You must promptly notify us of any unauthorized use of your account."</P>
  
          <P> <Span>Section 3: Services</Span></P>
          <P><B>Core Services Description</B></P>
          <P>Briefly outline your primary offerings:</P>
          <P>"Our Services include but not limited to tools for dataset creation, annotation, labeling, generative model training, data storage, image generation "</P>
          <P>"You can use our Services to train AI models on your custom datasets."</P>
          <P>"We offer a range of computational resources (GPUs) for model training, with pricing based on speed and availability."</P>
          <P>Optionally, mention if community, sharing, or marketplace features are planned for the future.</P>
  
          <P><B>Fees & Payment</B></P>
          <P>Pricing Model: Clearly explain your structure:</P>
          <P>"We operate on a credit-based system. Credits are used to pay for computational resources during model training."</P>
          <P>"Credits can be purchased in bundles, with discounts for larger purchases." [Include example price points]</P>
          <P>Payment Methods: "We accept payment via Stripe."</P>
          <P>Refunds: Outline your refund policy, if any. Specify circumstances for potential refunds (e.g., technical issues on your end), and cases where refunds are not granted.</P>
  
          <P><B>Availability & Disclaimers</B></P>
          <P>"As Is": "The Services are provided on an "as is" and "as available" basis.</P>
          <P>No Guarantees of Uptime: "We strive to maintain high availability, but occasional maintenance, updates, or unforeseen technical issues may cause temporary disruptions."</P>
          <P>External Factors: "We are not liable for interruptions caused by third-party services beyond our control (e.g., cloud service provider outages)."</P>
  
          <P> <Span>Section 4: User Content & Conduct</Span></P>
          <P><B>User Responsibility</B></P>
          <P>"You are solely responsible for any data, information, images, models, or other content ("Content") that you upload, create, or share through the Services."</P>
  
          <P><B>Content Rights</B></P>
          <P>Ownership: "You retain all ownership rights to your Content."</P>
          <P>Limited License (If Sharing is Enabled): "If you choose to share your Content with other users or make it publicly accessible on our platform, you grant decentre.io and its users a non-exclusive, worldwide, royalty-free license to access, use, and display such Content as permitted through the functionality of the Services."</P>
  
          <P><B>Prohibited Content</B></P>
          <P>Clear List: Explicitly state the types of content not allowed:</P>
          <P>Illegal or harmful content (hate speech, threats, child exploitation, etc.)</P>
          <P>Content infringing on others' intellectual property (copyrighted material, etc.)</P>
          <P>Spam or misleading content</P>
          <P>Content designed to disrupt the Services or harm other users</P>
  
          <P>Reporting Mechanism: "We encourage you to report any Content you believe violates this Agreement to [insert email or support mechanism]."</P>
  
          <P><B>Content Removal</B></P>
          <P>"We reserve the right to remove or disable access to any Content that violates this Agreement or that we deem, in our sole discretion, to be objectionable, harmful, or in violation of any third-party rights."</P>
  
          <P><B>User Conduct</B></P>
          <P>"You agree to use the Services in a respectful and lawful manner. You will not engage in any behavior that is harassing, abusive, defamatory, or otherwise harmful to others."</P>
          <P>Outline specific prohibited actions: "Prohibited actions include but are not limited to attempting to access other users' accounts without permission, distributing malicious software, or engaging in activities that could damage or interfere with the Services."</P>
        </div>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Container>
    </div>
  );
};

export default TermsAndConditions;
