import "./App.css";
import Footer from "./Components/Footer";
import Loader from "./Components/Loader";
import Navbar from "./Components/Navbar";
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import TermsAndConditions from "./Pages/T&Cs";
import { useAuth } from "./Components/Contexts/AuthContext";

const Main = lazy(() => import('./Pages/Main'));
const TLDR = lazy(() => import('./Pages/TLDR'));
const AppPage = lazy(() => import('./Pages/AppPage'));
const Contact = lazy(() => import('./Pages/Contact'));
const FAQ = lazy(() => import('./Pages/FAQ'));
const Purchase = lazy(() => import('./Pages/Purchase'));
const Login = lazy(() => import('./Pages/Auth/Login'));
const Register = lazy(() => import('./Pages/Auth/Register'));

function App() {
  const [loading, setLoading] = useState(true);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const checkDOMLoaded = () => {
      if (document.readyState === 'complete') {
        setLoading(false);
      } else {
        setTimeout(checkDOMLoaded, 100);
      }
    };

    checkDOMLoaded();
  }, []);

  return (
    <div className="App">
      {loading && <Loader />}
      <BrowserRouter>
        <Navbar />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/tldr" element={<TLDR />} />
            <Route path="/app-page" element={<AppPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/purchase" element={<Purchase />} />
            <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate to='/' />} />
            <Route path="/register" element={!isAuthenticated ? <Register /> : <Navigate to='/' />} />
            <Route path="*" element={<Main />} />
          </Routes>
        </Suspense>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;