import React, { useState } from 'react';
import logo from '../decentre-logo-transp.png';
import { NavLink, Outlet } from 'react-router-dom';
import {
  LogoContainer,
  NavItemButton,
  NavItemsContainer,
  NavbarContainer,
  HamburgerMenu,
} from './NavbarElements';
import { GiftOutlined, UserOutlined } from '@ant-design/icons';
import { useAuth } from './Contexts/AuthContext';
import { Avatar } from 'antd'
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isAuthenticated, logout, userData } = useAuth();


  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <NavbarContainer className="navbar">
        <LogoContainer to="/">
          <img src={logo} className='main-logo' alt="Logo" />
        </LogoContainer>
        <HamburgerMenu onClick={toggleMenu}>
          &#9776;
        </HamburgerMenu>
        <NavItemsContainer isOpen={isOpen}>
          <li className="nav-item">
            <NavLink to="/" className="nav-link" activeClassName="active" exact='true' onClick={toggleMenu}>Main</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/tldr" className="nav-link" activeClassName="active" onClick={toggleMenu}>TLDR</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/app-page" className="nav-link" activeClassName="active" onClick={toggleMenu}>APP Page</NavLink>
          </li>
         
          <li className="nav-item">
            <NavLink to="/faq" className="nav-link" activeClassName="active" onClick={toggleMenu}>FAQ</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/purchase" className="nav-link" activeClassName="active" onClick={toggleMenu}>Purchase</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/terms-and-conditions" className="nav-link" activeClassName="active" onClick={toggleMenu}>T&Cs</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/contact" className="nav-link" activeClassName="active" onClick={toggleMenu}>Contact</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="https://decentre-shop.fourthwall.com/en-gbp/" target="_blank" className="nav-link nav-link2" activeClassName="active" onClick={toggleMenu}>Store <GiftOutlined /></NavLink>
          </li>
          {!isAuthenticated && <li className="nav-item">
            <NavItemButton to="/login" className="nav-link" activeClassName="active" onClick={toggleMenu}>Login/Register<UserOutlined /></NavItemButton>
          </li>}
          {isAuthenticated && <Avatar style={{ backgroundColor: '#f56c13 ', verticalAlign: 'middle' }} size="large" gap={'1'}>
            {userData.name}
          </Avatar>}
          {isAuthenticated && <li className="nav-item">
            <NavItemButton to="/" className="nav-link" activeClassName="active" onClick={logout}> Logout<UserOutlined /></NavItemButton>
          </li>}
        </NavItemsContainer>
      </NavbarContainer>

      <Outlet />
    </>
  );
};

export default Navbar;
