import React from 'react'
import { FooterContainer } from './FooterElements'

const Footer = () => {
  return (
    <FooterContainer>
        DEVELOPED BY <a href='https://iomedia.io/' target="_blank">IOMEDIA</a>
    </FooterContainer>
  )
}

export default Footer