import styled from "styled-components";
import { Link as LinkR } from 'react-router-dom';

export const NavbarContainer = styled.div`
  width: 100%;
  min-height: 100px;
  background: #0b0f19;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 999;
`;

export const LogoContainer = styled(LinkR)`
  .main-logo {
    width: 150px;
  }
`;

export const NavItemsContainer = styled.ul`
  display: flex;
  list-style: none;
  align-items: center;
  margin-top: 10px;

  .nav-link {
    font-size: 16px;
    margin-left: 20px;
    text-decoration: none;
    color: #fff;

    &.active {
      border-bottom: 2px solid #f56c13;
    }
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background: #0b0f19;
    position: absolute;
    top: 90px;
    left: 0;

    .nav-link {
      margin: 10px 0;
      text-align: center;
    }
  }
`;

export const NavItemButton = styled(LinkR)`
  background-color: transparent;
  border-color: #f56c13;
  color: #f56c13;
  border-width: 2px;
  border-style: solid;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 20px;

  &:hover {
    background-color: #f56c13;
    border-color: #f56c13;
    color: #e9e9ed;
  }

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const HamburgerMenu = styled.div`
  display: none;
  font-size: 30px;
  cursor: pointer;
  color: #fff;
 
  @media (max-width: 768px) {
    display: block;
  }
`;

export const MobileMenu = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;
