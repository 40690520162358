import React from 'react';
import loaderone from '../assets/loader3.png'
import styled from 'styled-components';
import { grid } from 'ldrs'


// Default values shown


const Loader = () => {
  grid.register()

  return (
    <LoaderContainer>
      <l-grid
        size="150"
        speed="1.5"
        color="#f56c13"
      ></l-grid>
      {/* <img src={loaderone} alt="Loading..." /> */}
    </LoaderContainer>
  );
};

export default Loader;

const LoaderContainer = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
background-color: rgba(255, 255, 255, 0.8);
z-index: 9999;
img {
    width: 250px;
    height: 250px;
  }
`;


