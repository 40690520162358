import styled from "styled-components"

export const FooterContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height : 50px;
  background-color: #0b0f19;
  color: #E9e9ed;
  padding: 10px;
  text-align: center;
  // border: 3px solid #46505e;
  font-family: 'Comfortaa', sans-serif;
  z-index : 999;

  a{
    color : #6294af;
    cursor : pointer;
  }

`